<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte de Ventas CV"
            class="tran"
          ></s-toolbar>
          <v-card>
            <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
              <v-col cols="12" lg="4" md="4" class="mt-2 pl-0 ml-3">
                <v-btn
                  rounded
                  :color="'info'"
                  small
                  @click="runView()"
                  class="mr-3"
                  >Buscar</v-btn
                >
                <v-btn rounded :color="'error'" small @click="downloadReport()"
                  >Descargar</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <v-col cols="12">
      <v-row>
        <v-spacer />
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Codigo de venta"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :height="items.length > 0 ? '400px' : 'auto'"
            :headers="headers"
            :items="items"
            dense
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 50, 100],
            }"
            class="fixed-first-column"
            :search="search"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";
import * as XLSX from "xlsx";

export default {
  props: {},
  components: {},
  data() {
    return {
      processing: false,
      messageProcessing: "",

      items: [],
      headers: [],
      report: {},
      search: "",

      objMounth: null,
      txtYear: this.$fun.getYear(),
    };
  },

  methods: {
    runView() {
      this.messageProcessing = "";
      this.report.QryParamsArray = "Month";
      this.report.QryParamsArrayValue = ""; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      this.report.QryNameProcedure = "ClcSalesCv_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.items = resp.data;
            this.headers = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
              filterable: item === "Codigo de Venta" ? true : false,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    downloadReport() {
      const SheetNames = ["Report"];
      const DataTables = [this.items];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "Reporte de Ventas CV"
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
