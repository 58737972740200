<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte de Ventas RE"
            class="tran"
          ></s-toolbar>
          <v-card>
            <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
              <v-col sm="6" cols="6" lg="2" md="3">
                <s-select-definition
                  :def="1215"
                  v-model="objMounth"
                  return-object
                  label="Seleccione periodo"
                ></s-select-definition>
              </v-col>
              <v-col cols="4" lg="4" md="4" class="mt-3 pl-0 ml-3">
                <v-btn
                  rounded
                  :color="'info'"
                  small
                  @click="runView()"
                  class="mr-3"
                  >Buscar</v-btn
                >
                <v-btn
                  rounded
                  :color="'error'"
                  small
                  @click="downloadReport()"
                  :disabled="disabledBtnDownload"
                  >Descargar</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <v-col cols="12">
      <v-row>
        <v-spacer />
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Codigo de venta"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <gral-table
            :headers="headers"
            :items="items"
            :columnsNotNumber="[
              'CodigoVenta',
              'DNI',
              'AnioVencimiento',
              'CodigoLetra',
              'AnioVenta',
              'AnioPago',
              'NroOperacion',
            ]"
            :propsdinamic="{ search, class: 'fixed-first-column' }"
          ></gral-table>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";
import GralTable from "../GralTable.vue";

export default {
  props: {},
  components: { GralTable },
  data() {
    return {
      processing: false,
      messageProcessing: "",

      items: [],
      headers: [],
      report: {},

      objMounth: null,
      txtYear: this.$fun.getYear(),
      search: "",
      objMounth: null,
      disabledBtnDownload: true,
    };
  },

  methods: {
    runView() {
      this.messageProcessing = "";
      this.report.QryParamsArray = "Period";
      this.report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      this.report.QryNameProcedure = "ClcSalesRE_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.items = resp.data;
            this.headers = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
              filterable: item === "CodigoVenta" ? true : false,
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
      this.disabledBtnDownload = false;
    },
    downloadReport() {
      const SheetNames = ["Report"];
      const DataTables = [this.items];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "Ventas RE " + this.objMounth.DedDescription
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
